import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import './styles/contact.css';

const ContactForm = (props) => {
    const [stateMessage, setStateMessage] = useState(null);
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      emailjs
        .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {publicKey: process.env.REACT_APP_PUBLIC_KEY}
          )
        .then(
          () => {
            setStateMessage('Message sent!');
            console.log('Success!')
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); 
            e.target.reset();
          },
          (error) => {
            props.screenSize.width<=900? 
            setStateMessage('Something went wrong, please use the email link below to email me directly instead.'):
            setStateMessage('Something went wrong, please use the email link to the right to email me directly instead.');
            console.log('Email failed!...', error)
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); 
          }
        );
    };

    return (
      <form ref={form} onSubmit={sendEmail} className="contactForm border rounded-xl p-8 flex flex-col items-center md:ml-12 md:mr-4 md:p-4">
        <div className="contactSection">
            <label className="contactLabel" htmlFor="from_name">Name</label>
            <input className="contactInput rounded-xl" type="text" name="from_name" />
        </div>
        <div className="contactSection">
            <label className="contactLabel" htmlFor="from_email">Email</label>
            <input className="contactInput contactEmail rounded-xl" type="email" name="from_email" />
        </div>
        <div className="contactSection">
            <label className="contactLabel" htmlFor="message">Enquiry</label>
            <p className="smallText contactText">Send me an email by typing your message here and clicking the send button below. Please also include a contact number, and best contact time, if you would prefer a call instead of an email response.</p>
            <textarea className="contactInput contactMessage rounded-xl" name="message" />
        </div>
        <motion.button  whileTap={{ scale: 0.9 }} className={clsx("flex mb-4 mt-4 border rounded-xl p-2 transition-colors duration-75 drop-shadow-lg hover:bg-gray-300 hover:border-fuchsia-700", stateMessage ? 'bg-gray-300 border-fuchsia-900' : 'bg-gray-100 border-fuchsia-600')}>
            <input className={clsx("merri text-[2.2vmin] italic font-bold text-center whitespace-nowrap hover:text-fuchsia-700", stateMessage ? 'text-fuchsia-950':'text-fuchsia-700')} type="submit" value="Send" />
        </motion.button>
        {stateMessage && <p>{stateMessage}</p>}
      </form>
    );
  };
  export default ContactForm;