import emdrBanner from '../components/images/emdrHeader.jpg';
import { NavLink } from 'react-router-dom';

const EMDR = (props) => {
    props.setBanner(emdrBanner);
    props.setPosition('center');
    props.setTitle("EMDR");
    props.setSubTitle("");
    props.setFees("all");

    return(
        <div className="page">
            <p className="normalText">
                This type of therapy is designed to stop the distress of past events. This distress might present as flashbacks, nightmares, avoidance, hypervigilance and just feeling very overwhelmed. The symptoms may be triggered by sights, sounds, thoughts and feelings.
            </p>
            <p className="normalText">
                The therapy is designed to change how these memories are stored in the brain, making them less powerful. You should, over time experience a reduction in anxiety & stress associated with trauma. The therapy is recommended for PTSD, phobias & fears, anxiety, low self-esteem, depression.
            </p>
            <NavLink to="/fees" ><button className="feesBtn" onClick={() => props.setFees("counselling")}>Fees</button></NavLink>
        </div>
    )
};

export default EMDR;


