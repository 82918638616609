import supervisionBanner from '../components/images/supervisionHeader.jpg';
import { NavLink } from 'react-router-dom';

const Supervision = (props) => {
    props.setBanner(supervisionBanner);
    props.setPosition('bottom');
    props.setTitle("Supervision");
    props.setSubTitle("");
    props.setFees("all");
    

    return(
        <div className="page">
            <p className="normalText">
                I offer 1/1 and group supervision to both qualified and trainee counsellors, and those in related professions. My approach is Person-Centred/Experiential and collaborative. I try to offer what I value in my own supervision, which is a safe space to celebrate and challenge myself in the presence of another. I offer my full engagement and transparency to help you to maintain safe, ethical and beneficial practice.
            </p>
            <NavLink to="/fees" ><button className="feesBtn" onClick={() => props.setFees("supervision")}>Fees</button></NavLink>
        </div>
    )
};

export default Supervision;