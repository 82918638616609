import { useEffect, useState } from 'react';
import FullNav from './navbars/fullNav';
import ThreeNav from './navbars/3Nav';
import FourNav from './navbars/4Nav';
import FiveNav from './navbars/5Nav';
import SmallNav from './navbars/smallNav';

const Navbar = () => {
    const [ screenSize, setScreenSize ] = useState(getCurrentDimension());
    function getCurrentDimension(){
      return {
        width: window.innerWidth,
        height: window.innerHeight
      }
    }
    useEffect(() => {
      const updateDimension = () => {
        setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
      return(() => {
        window.removeEventListener('resize', updateDimension);
      })
    }, [screenSize])

  function displayNav(){
    if(screenSize.width <= 1300){
      if(screenSize.width <= 1150){
        if(screenSize.width <= 900){
          if(screenSize.width <= 700){
            return(
              <SmallNav />
            )
          }else{
            return(
              <ThreeNav  />
            )
          }
        }else{
          return(
            <FourNav />
          )
        }
      }else{
        return(
          <FiveNav />
        )
      }
    }else{
      return(
        <FullNav />
      )
    }
  }
  return (
    <>
    {
      displayNav()
    }
    </>
  )
}

export default Navbar;