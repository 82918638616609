import contactBanner from '../components/images/contactHeader.jpg';
import '../components/styles/contact.css';
import ContactForm from '../components/contact';
import { useState, useEffect } from 'react';

const Contact = (props) => {
    props.setBanner(contactBanner);
    props.setPosition('center');
    props.setTitle("Contact");
    props.setSubTitle("");
    props.setFees("all");
    const [ screenSize, setScreenSize ] = useState(getCurrentDimension());
    function getCurrentDimension(){
      return {
        width: window.innerWidth,
        height: window.innerHeight
      }
    }
    useEffect(() => {
      const updateDimension = () => {
        setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
      return(() => {
        window.removeEventListener('resize', updateDimension);
      })
    }, [screenSize])

    const width = screenSize.width/2;
    const height = width * 0.75
  
    return(
      <div className="page">
        <div className="formAndMap">
          <ContactForm />
          <div className="mapAndContact">
            <iframe title="Liz Hynes Therapy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1189.448002791908!2d-2.6246285627766626!3d53.39880015311322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b0306a727fc25%3A0x4ba6945d4f64009a!2sLiz%20Hynes%20Therapy!5e0!3m2!1sen!2suk!4v1668997043770!5m2!1sen!2suk" width={width} height={height} style={{border:0}} loading="lazy">
            </iframe>
            <p className="contactLabel">Phone: <a href="tel:+447989639386" className="email">07989639386</a></p>
            <p className="contactLabel">Email: <a href="mailto:liz@lizhynestherapy.co.uk" className="email">liz@lizhynestherapy.co.uk</a></p>
          </div>
        </div>
      </div>
    )
};

export default Contact;