import teachingBanner from '../components/images/teachingHeader.jpg';
import { NavLink } from 'react-router-dom';

const Teaching = (props) => {
    props.setBanner(teachingBanner);
    props.setPosition('bottom');
    props.setTitle("Teaching & Facilitation");
    props.setSubTitle("");
    props.setFees("all");

    return(
        <div className="page">
                <p className="normalText">
                I currently work at a local college training new counsellors Level 2 - Level 4.
                </p>
                <p className="normalText">
                    I can offer bespoke training and workshops to corporate organisations, colleges & training providers, workplaces and voluntary organisations and networking/ personal development groups. Please contact me for information.
                </p>
                <NavLink to="/fees" ><button className="feesBtn" onClick={() => props.setFees("teaching")}>Fees</button></NavLink>
            
        </div>
    )
};

export default Teaching;