import './styles/home.css';
import homeBanner from '../components/images/supervisionHeader.jpg';
import liz from '../components/images/liz.jpg';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

const Home = (props) => {
    props.setBanner(homeBanner);
    props.setPosition('bottom');
    props.setTitle("Liz Hynes");
    props.setSubTitle("Counselling Therapy");
    props.setFees("all");
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(useGSAP);
    const [shown, setShown] = useState({how: false, offer: false});
    const arrow="⌃"
    
    useGSAP(()=> {
      const tl = gsap.timeline({
        scrollTrigger:{
          scrub:2,
          pin: false,
          trigger: "#p1",
          start: "top 75%",
          end: "+=" + window.innerHeight * 2,
          yoyo: false
        },
      });
      tl.timeScale(0.75)
      tl.fromTo("#p1", {opacity:0, scale:0.5}, { opacity:1, scale:1});
      tl.fromTo("#p2", {opacity:0, scale:0.5}, { opacity:1, scale:1});
      tl.fromTo("#p3", {opacity:0, scale:0.5}, { opacity:1, scale:1});
      tl.fromTo("#p4", {opacity:0, scale:0.5}, { opacity:1, scale:1});
      tl.to("#p1", {scale: 0.5, opacity:0});
      tl.to("#p2", {scale: 0.5, opacity:0});
      tl.to("#p3", {scale: 0.5, opacity:0});
      tl.to("#p4", {scale: 0.5, opacity:0});
    }, [])
    function showMore(){
        if (!shown.how){
            setShown({how: true, offer: false});
            const thisArrow = document.getElementById("arrow");
            thisArrow.style.transform = 'rotate(0deg)';
            const thatArrow = document.getElementById("arrow2");
            thatArrow.style.transform = 'rotate(180deg)';
        }else{
            setShown({how: false, offer: false}) ;
            const thisArrow = document.getElementById("arrow");
            thisArrow.style.transform = 'rotate(180deg)';
            const thatArrow = document.getElementById("arrow2");
            thatArrow.style.transform = 'rotate(180deg)';
        }
    };
    function showMore2(){
      if (!shown.offer){
          setShown({how: false, offer: true});
          const thisArrow = document.getElementById("arrow2");
          thisArrow.style.transform = 'rotate(0deg)';
          const thatArrow = document.getElementById("arrow");
          thatArrow.style.transform = 'rotate(180deg)';
      }else{
          setShown({how: false, offer: false}) ;
          const thisArrow = document.getElementById("arrow2");
          thisArrow.style.transform = 'rotate(180deg)';
          const thatArrow = document.getElementById("arrow");
          thatArrow.style.transform = 'rotate(180deg)';
      }
  };
    useEffect(() => {
      const thisArrow = document.getElementById("arrow")
      thisArrow.style.transform = 'rotate(180deg)'
    }, [])

  return(
    <div className="page">
      <div className="mr-56 whitespace-nowrap">
        <h1 className="heading text-fuchsia-950">Meet Liz...</h1>
      </div>
      <div className="pictureText">
        <img src={liz} alt="Liz Hynes" className="lizImg" title="Liz Hynes" />
        <div className="homeText">
          <p className="normalText personal" id="p1">
            My personal life over the past 24 years has taught me, and informed my practice, and areas of interest, in equal measures to my continuing training and development over this time. My family life experiences have included disability, loss, addiction, issues of sexuality, neurodiversity, hardship and trauma.
          </p>
          <p className="normalText personal" id="p2">
            I am able to sit with you and your experiences, calmly and confidently and to assist you to work through intense emotion. I will help you to trust in yourself, to regulate and to process in order to lead a more fulfilling life.
          </p>
          <p className="normalText" id="p3">
            I have over 20 years’ experience as a counsellor/supervisor/trainer. I have worked in the NHS, with blue light services and in the third sector. I now run my private practice and counselling for employee assistance programmes, where people access counselling through their workplace. I also teach counselling at a local college, helping others to qualify to do this amazing & rewarding work for which I have such a passion.
          </p>
          <p className="normalText" id="p4">
            I was trained in the Person-Centred Approach to MA level, which is based on offering acceptance, empathic understanding and congruence, meaning I will offer my full engagement and my authentic responses to your issues, without judgement. I have further training in working with couples, supervision for counsellors, trauma informed therapy including EMDR (Eye Movement Desensitisation Reprocessing), solution focused therapy and teaching adults. I now work in a more integrative way to suit each individual.
          </p>
          <div className="flex flex-row items-start justify-center w-full relative mb-20">
            <div className="flex flex-col m-8">
            <motion.button onClick={showMore} whileTap={{ scale: 0.9 }} className={clsx("flex absolute left-0 mt-4 border rounded-xl p-2 transition-colors duration-75 drop-shadow-lg", shown.how ? 'bg-gray-300 border-fuchsia-900' : 'bg-gray-100 border-fuchsia-600')}>
            <h2 className={clsx("merri text-[2.2vmin] italic font-bold text-center whitespace-nowrap", shown.how ? 'text-fuchsia-950':'text-fuchsia-700')} id="p5">
                How I work... <span id="arrow">{arrow}</span>
              </h2>
              </motion.button>
              <AnimatePresence mode="wait">
                {shown.how && (
                  <motion.div id="p6" transition={{
                    type: 'easein',
                    duration: 0.6,
                    bounce: 0.1
                  }}
                  initial={{ opacity: 0, scale: 0.2, y: -90, x:-150}} 
                  animate={{ opacity: 1, scale: 1, y:70, x:0 }} 
                  exit={{ opacity: 0, scale: 0.3, y: -90, x:-150 }} 
                  >
                    <p className="normalText text-left ml-4 mb-8 w-[60vw]">
                      Talking is often enough and usually a starting point, however sometimes the words don’t come, or are not enough. Working creatively is a more powerful way for some, to explore distress and pain. My practice room is a safe and confidential space and is a trove of resources to assist in creative connectivity, should you wish to explore in this way. 
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div  className="flex flex-col m-8">
              <motion.button onClick={showMore2} whileTap={{ scale: 0.9 }} className={clsx("flex absolute right-0 mt-4 border rounded-xl p-2 transition-colors duration-75 drop-shadow-lg", shown.offer ? 'bg-gray-300 border-fuchsia-900' : 'bg-gray-100 border-fuchsia-600')}>
                <h2 className={clsx("merri text-[2.2vmin] italic font-bold text-center whitespace-nowrap", shown.offer ? 'text-fuchsia-950':'text-fuchsia-700')} id="p7">
                 What I offer... <span id="arrow2">{arrow}</span>
                </h2>
              </motion.button>
              <AnimatePresence mode="wait">
                {shown.offer && (
                  <motion.div id="p8" transition={{
                    type: 'easein', 
                    duration: 0.6,
                    bounce: 0.1
                  }}
                  initial={{ opacity: 0, scale: 0.2, y: -90, x:150}} 
                  animate={{ opacity: 1, scale: 1, y:70, x:0 }} 
                  exit={{ opacity: 0, scale: 0.3, y: -90, x:150 }} 
                  >
                    <p className="normalText text-left ml-4 mb-8 w-[60vw]">
                      I offer a safe, confidential space, where together we can explore your dis-ease, through talking or other experiential/creative means. I have faced many challenges in life and know how difficult it can be to allow ourselves to be vulnerable, and also how rewarding it is to face our vulnerabilities with another who can facilitate us to find strength, personal power and build resilience and make lasting changes. This would be my wish for you should you choose to work with me.
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBanner">
        <p>When I work with individuals, I meet you as a unique and whole person who is struggling in some way to live a content and joyful life, not someone with an issue or label to be fixed.</p>
      </div>

      <div className="bottomBanner">
        <h2 className="quoteText">“The curious paradox is that when I accept myself just as I am then I can change.”</h2>
        <h3 className="quoteReference"><b>Carl Rogers</b> – Founder of the Person-Centred Approach to Counselling</h3>
      </div>
      
    </div>
  )
};

export default Home;