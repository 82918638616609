import Navbar from './components/navbar'
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useState } from 'react'; 
import Home from './pages/home'; 
import OneToOne from './pages/oneToOne';
import Couples from './pages/couples'; 
import Supervision from './pages/supervision';
import EMDR from './pages/emdr'; 
import Teaching from './pages/teaching';
import Fees from './pages/fees'; 
import Contact from './pages/contact';
import Terms from './pages/terms'; 
import Footer from './components/footer';

function App() {
  const [banner, setBanner] = useState()
  const [title, setTitle] = useState("Liz Hynes")
  const [subTitle, setSubTitle] = useState("Counselling Therapy")
  const [fees, setFees] = useState("all")
  const [position, setPosition] = useState('bottom')

  return (
    <div className="App">
      
      <Navbar />
      <header className="App-header" style={{backgroundImage: `linear-gradient(to bottom, white, transparent 5%), url(${banner})`, backgroundPositionY: `${position}` }}>
        <h1 className="title">{title}</h1>
        <h2 className="subTitle">{subTitle}</h2>
          <a className="linkText" href="https://www.bacp.co.uk" rel="noreferrer" target="_blank">
            <div className="bg-stone-300/25 py-0 px-2 rounded-xl hover:bg-stone-300/60">
              BACP Accredited
            </div>
          </a>
        
      </header>
      <div className="App-body">
      <Routes> 
          <Route key="home" index element={<Home setBanner={setBanner} setTitle={setTitle} setSubTitle={setSubTitle} setFees={setFees} setPosition={setPosition}/>} />
          <Route key="oneToOne" path="oneToOne" element={<OneToOne setBanner={setBanner} setPosition={setPosition} setTitle={setTitle} setSubTitle={setSubTitle} setFees={setFees} />} />
          <Route key="couples" path="couples" element={<Couples setBanner={setBanner} setPosition={setPosition} setTitle={setTitle} setSubTitle={setSubTitle} setFees={setFees} />} />
          <Route key="supervision" path="supervision" element={<Supervision setBanner={setBanner} setPosition={setPosition} setTitle={setTitle} setSubTitle={setSubTitle} setFees={setFees} />} />
          <Route key="emdr" path="emdr" element={<EMDR setBanner={setBanner} setTitle={setTitle} setPosition={setPosition} setSubTitle={setSubTitle} setFees={setFees} />} />
          <Route key="teaching" path="teaching" element={<Teaching setBanner={setBanner} setTitle={setTitle} setSubTitle={setSubTitle} setFees={setFees} setPosition={setPosition} />} />
          <Route key="fees"  path="fees" element={<Fees fees={fees} setFees={setFees} setBanner={setBanner} setTitle={setTitle} setSubTitle={setSubTitle} setPosition={setPosition} />} />
          <Route key="contact" path="contact" element={<Contact setBanner={setBanner} setPosition={setPosition} setTitle={setTitle} setSubTitle={setSubTitle} setFees={setFees} />} />
          <Route key="terms"  path="terms" element={<Terms setBanner={setBanner} setTitle={setTitle} setSubTitle={setSubTitle} setFees={setFees} setPosition={setPosition} />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
