import './styles/footer.css';
import logo from './images/BACPLogo.jpeg';
import { NavLink } from 'react-router-dom';

const Footer = () => {

    return(
        <div className="footerContainer">
            <div className="footer">
                <a href="https://www.bacp.co.uk" rel="noreferrer" target="_blank"><img src={logo} alt="BACP Logo" title="BACP Logo" className="footerLogo" /></a>
                <div className="footerText">    
                    <p className="smallText">
                        I am an accredited member of BACP. I am fully insured and registered with the ICO for data privacy.
                    </p>
                    <NavLink className="termsLink" to="/terms">Terms & Privacy</NavLink>
                </div>
            </div>
        </div>
    )
};

export default Footer;
