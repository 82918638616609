import './styles/terms.css';
import homeBanner from '../components/images/homeHeader.jpg';
import{ useState } from 'react';

const Terms = (props) => {
    props.setBanner(homeBanner);
    props.setPosition('bottom');
    props.setTitle("Terms, Conditions");
    props.setSubTitle("& Privacy Statement");
    props.setFees("all");
    const [ show, setShow ] = useState(false);

   
    
    return(
        <div className="page">
            <div className="terms">
                <h1 className="heading priv text-zinc-700">Website Usage Terms & Conditions</h1>
                <p className="privacyText text-zinc-700">Welcome to my website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with my privacy policy govern Liz Hynes’ relationship with you in relation to this website.</p>
                <p className="privacyText text-zinc-700"> If you disagree with any part of these terms and conditions, please do not continue to use this website.</p>
                <p className="privacyText text-zinc-700">The term 'Liz Hynes', 'Liz Hynes Therapy', 'me', 'we', 'I' or 'us' refers to the owner of the website whose registered office is in Warrington, WA5, UK.</p>
                <p className="privacyHeading text-zinc-700">The use of this website is subject to the following terms of use:</p>
                <ul className="privacyText text-zinc-700">
                    <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                    <li>Your use of any information or materials on this website is entirely at your own risk, for which I shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                    <li>This website contains material which is owned by or licensed to me. This material includes, but is not limited to, the design, layout, look, appearance and graphics of the website. Reproduction is prohibited other than in accordance with local copyright laws.</li>
                    <li>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</li>
                    <li>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
                    <li>This website also includes links to other websites. These links are provided for your convenience to provide further information. They do not signify that I endorse the linked website(s) and I have no responsibility for the content of the linked website(s).</li>
                    <li>Neither me, nor any third parties, provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and I expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                    <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.</li>
                </ul>
            </div>
            <div className="privacy border border-2 border-slate-400 border-opacity-6-
            rounded-xl mt-5 mb-8 p-2">
                <h1 className="subHeading priv text-fuchsia-950">Privacy Policy</h1>
                <p className="privacyText">Liz Hynes does not collect or hold any information from/about the visitors to this site.</p>
                <p className="privacyText">This is subject to change at any time, without prior notification.</p>
                <p className="privacyText">Should this change, the privacy policy will be updated to reflect how information is used and stored.</p>
                <div className="privacyContact mt-8 mb-4">
                    <h2 className="privacyHeading text-fuchsia-950 cursor-pointer" onClick={()=>setShow(!show)}>Contact Details:</h2>
                    { show &&<>
                    <p className="privacyText"><strong className="privacyLabel text-fuchsia-950">Name:</strong> Liz Hynes</p>
                    <p className="privacyText"><strong className="privacyLabel text-fuchsia-950">Address:</strong> 81 Stratton Road, Great Sankey, Warrington, WA5, UK</p>
                    <p classr2qr2
                    Name="privacyText m-5"><strong  className="privacyLabel text-fuchsia-950">E-Mail:</strong> <a href="mailto:admin@lizhynestherapy.co.uk" className="email text-zinc-300 text-sm">admin@lizhynestherapy.co.uk</a></p></>}
                </div>
                
            </div>
            <p className="privacyDate text-zinc-400 text-[14px]">Date updated: 30/08/24</p>
        </div>
    )
};

export default Terms;