import '../styles/navbar.css';
import logo from '../images/navLogo.jpg';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

const Navbar = () => {
    const [showMore, setShowMore] = useState("none");
    const [showCounselling, setShowCounselling] = useState("none");

    return(
        <div className="navbar bg-gradient-to-b from-fuchsia-900 via-70% via-fuchsia-200 to-transparent">
            <div className="navBtn">
                    <NavLink className={({isActive}) => isActive ? "current": "pages" }  to="/"  ><img src={logo} alt="Liz Hynes Counselling Therapy Logo" className="navLogo" /></NavLink>
                </div>
          
                <div className="navBtn">
                    <NavLink className={({isActive}) => isActive ? "current": "pages" }  to="/"  >Home</NavLink>
                </div>
                <div className="navMenu" onMouseEnter={() => setShowCounselling("block")} onMouseLeave={() => setShowCounselling("none")}>
                    <div className="navBtn">Counselling</div> 
                    <AnimatePresence mode="wait">
                      {showCounselling === 'block' && (
                        <motion.div transition={{
                        type: 'easein', 
                        duration: 0.4
                      }}
                      initial={{ opacity: 0, y:-65, x:40 }} 
                      animate={{ opacity: 1, y:-40, x:40 }} 
                      exit={{ opacity: 0, y:-65, x:40 }} className="navList whitespace-nowrap" style={{display:showCounselling}}
                      >
                    <ul className="navList" style={{display:showCounselling}}>
                        <li className="navListItem">
                            <NavLink className={({isActive}) => isActive ? "currentList": "list" }  to="/oneToOne" >One To One</NavLink>
                        </li>
                        <li className="navListItem">
                            <NavLink className={({isActive}) => isActive ? "currentList": "list" }  to="/couples" >Couples</NavLink>
                        </li>
                    </ul>
                    </motion.div>)}
                    </AnimatePresence>
                </div>
                <div className="navMenu" onMouseEnter={() => setShowMore("block")} onMouseLeave={() => setShowMore("none")}>
                    <div className="navBtn">More</div> 
                    <AnimatePresence mode="wait">
                      {showMore === 'block' && (
                        <motion.div transition={{
                        type: 'easein', 
                        duration: 0.4
                      }}
                      initial={{ opacity: 0, y:-65, x:-140 }} 
                      animate={{ opacity: 1, y:-40, x:-140 }} 
                      exit={{ opacity: 0, y:-65, x:-140 }} className="navList whitespace-nowrap" style={{display:showMore}}
                      >
                    <ul className="navList" style={{display:showMore}}>
                        <li className="navListItem">
                            <NavLink className={({isActive}) => isActive ? "currentList": "list" }  to="/supervision" >Supervision</NavLink>
                        </li>
                        <li className="navListItem">
                            <NavLink className={({isActive}) => isActive ? "currentList": "list" }  to="/emdr" >EMDR</NavLink>
                        </li>
                        <li className="navListItem">
                            <NavLink className={({isActive}) => isActive ? "currentList": "list" }  to="/teaching" >Teaching/Facilitation</NavLink>
                        </li>
                        <li className="navListItem">
                            <NavLink className={({isActive}) => isActive ? "currentList": "list" }  to="/fees" >Fees</NavLink>
                        </li>
                        <li className="navListItem">
                            <NavLink className={({isActive}) => isActive ? "currentList": "list" }  to="/contact" >Contact</NavLink>
                        </li>
                    </ul>
                    </motion.div>)}
                    </AnimatePresence>
                </div>
            </div>
    )
};

export default Navbar;
