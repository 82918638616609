import oneToOneBanner from '../components/images/oneToOneHeader.jpg';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';
import { useState } from 'react';
import { motion } from 'framer-motion';

const OneToOne = (props) => {
    props.setBanner(oneToOneBanner);
    props.setPosition('bottom');
    props.setTitle("Counselling");
    props.setSubTitle("One To One");
    props.setFees("all");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");

    function openModal(content){
        setModalIsOpen(true)
        setModalTitle(content.title)
        setModalBody(content.body)
        setTimeout(() => {
            closeModal()
        },8000)
    }
    function closeModal(){
        setModalIsOpen(false)
    }
    const face = {
        title: "Face To Face",
        body: "I am based in Warrington for face to face sessions. These last 50 minutes and most clients have sessions for around 6-8weeks."
    }
    const phone = {
        title: "Telephone",
        body: "Telephone appointments can be scheduled for those who aren't in the local area. We will agree a time together, when I will call you. I ask that you are in a quiet space where you are alone and able to talk freely and openly."
    }
    const online = {
        title: "Online",
        body: "Appointments can take place using an online platform if it is more convenient. We would agree a time together, when I will call you. I ask that you are in a quiet space where you are alone and able to talk freely and openly."
    }

  return(
    <div className="page">
      <div className="banner">
        <h2 className="w-fit mx-8 px-4 hover:text-fuchsia-900 hover:drop-shadow-lg whitespace-nowrap hover:border hover:border-fuchsia-800 hover:rounded-xl" title="Click me!" onClick={() => openModal(face)}>Face To Face</h2>
        <h2 className="w-fit mx-8 px-4 hover:text-fuchsia-900 hover:drop-shadow-lg hover:border hover:border-fuchsia-800 hover:rounded-xl" title="Click me!"  onClick={() => openModal(online)}>Online</h2>
        <h2 className="w-fit mx-8 px-4 hover:text-fuchsia-900 hover:drop-shadow-lg hover:border hover:border-fuchsia-800 hover:rounded-xl" title="Click me!"  onClick={() => openModal(phone)}>Telephone</h2>
      </div>
      <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className="counsellingModal"
      >
        <div className="mainModalDiv">
          <h1 className="mTitle">{modalTitle}</h1>
          <h2 className="normalText mBody">{modalBody}</h2>
        </div>
      </Modal>
      <p className="text-center normalText ">
        I truly experience counselling at its best in person and this is always my preference and recommendation. However, I understand that this is sometimes, for many reasons, not possible or preferable. Therefore, I also offer online and telephone counselling. My practice is based in Warrington.
      </p>
      <p className="normalText">
        I offer a flexible approach to sessions including some evenings and weekend appointments. You will decide the frequency of our meetings. I offer an initial 30-minute introduction session to see if you feel comfortable to work with me, it is really important that your counsellor is a good match, and that you feel comfortable. We will discuss how you wish to work, and some boundaries in the first session, in order to ensure you feel safe, including how and when to contact each other.
      </p>
      <NavLink to="/fees" ><motion.button onClick={() => props.setFees("counselling")}  whileTap={{ scale: 0.9 }} className="group w-fit h-fit flex m-4 px-4 border rounded-xl p-2 transition-colors duration-500 drop-shadow-lg border-fuchsia-600 hover:bg-gray-300 hover:border-fuchsia-700">
      <h2 className="merri text-[2.5vmin] transition-colors duration-500 italic font-bold text-center whitespace-nowrap text-fuchsia-600 group-hover:text-fuchsia-700">Fees</h2>
      </motion.button></NavLink>
    </div>
  )
};

export default OneToOne;