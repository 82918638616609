import feesBanner from '../components/images/feesHeader.jpg';

const Fees = (props) => {
    props.setBanner(feesBanner);
    props.setPosition('bottom');
    props.setTitle("Fees");
    props.setSubTitle("");
    
    if (props.fees === "counselling"){
        return(
            <div className="page">
                <h1 className="subHeading mb-4">Counselling</h1>
                <ul className="priceList mb-4">
                    <li>
                        1 on 1/Couples - £50 per hour
                    </li>
                    <li>
                    Family & groups – please contact me for details on <a href="tel:+447989639386" className="email">07989639386</a> or <a href="mailto:liz@lizhynestherapy.co.uk" className="email">liz@lizhynestherapy.co.uk.</a>
                    </li>
                </ul>
                <button className="feesBtn" onClick={() => props.setFees("all")}>All Fees</button>
            </div>
        )
    }
    else if(props.fees === "supervision"){
        return(
            <div className="page">
                <h1 className="subHeading mb-4">Supervision</h1>
                <ul className="priceList mb-4">
                    <li>
                        Supervision - £60 per hour
                    </li>
                    <li>
                        Group availble - please enquire on <a href="tel:+447989639386" className="email">07989639386</a> or <a href="mailto:liz@lizhynestherapy.co.uk" className="email">liz@lizhynestherapy.co.uk.</a>
                    </li>
                </ul>
                <button className="feesBtn" onClick={() => props.setFees("all")}>All Fees</button>
            </div>
        )
    }
    else if(props.fees === "teaching"){
        return(
            <div className="page">
                <h1 className="subHeading mb-4">Training/Workshops</h1>
                <p className="normalText">Please contact me for details on <a href="tel:+447989639386" className="email">07989639386</a> or <a href="mailto:liz@lizhynestherapy.co.uk" className="email">liz@lizhynestherapy.co.uk.</a></p>
                <button className="feesBtn" onClick={() => props.setFees("all")}>All Fees</button>
            </div>
        )

    }else {
        return(
            <div className="page">
                <h1 className="subHeading mb-4">Counselling</h1>
                <ul className="priceList mb-4">
                    <li>
                        1 on 1/Couples - £50 per hour
                    </li>
                    <li>
                    Family & groups – please contact me for details on <a href="tel:+447989639386" className="email">07989639386</a> or <a href="mailto:liz@lizhynestherapy.co.uk" className="email">liz@lizhynestherapy.co.uk.</a>
                    </li>
                </ul>
                <h1 className="subHeading mb-4">Supervision</h1>
                <ul className="priceList mb-4">
                    <li>
                        Supervision - £60 per hour
                    </li>
                    <li>
                        Group availble - please enquire on <a href="tel:+447989639386" className="email">07989639386</a> or <a href="mailto:liz@lizhynestherapy.co.uk" className="email">liz@lizhynestherapy.co.uk.</a>
                    </li>
                </ul>
                <h1 className="subHeading">Training/Workshops</h1>
                <p className="normalText">Please contact me for details on <a href="tel:+447989639386" className="email">07989639386</a> or <a href="mailto:liz@lizhynestherapy.co.uk" className="email">liz@lizhynestherapy.co.uk.</a></p>
            </div>
        )
    }
};

export default Fees;